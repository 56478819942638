export default {
  general: {
    filters: "Filtry",
    save: "Zapisz",
    cancel: "Anuluj",
    add: "Dodaj",
    download: "Pobierz",
    attachedFile: "Załączony plik",
  },
  badges: {
    active: "Aktywny",
    notActive: "Nieaktywny",
    subscription: "Subskrypcja",
    once: "Płatność jednorazowa",
  },
  order: {
    setOrder: "Ustal kolejność",
  },
  routes: {
    hero: {
      main: "Hero",
      form: "Dodaj",
      list: "Lista",
      description2: "Opis 2",
      location: "Link (jeśli ma być inny, niż domyślny)",
      description_link: "Opis linku (jeśli ma być inny, niż domyślny)",
      title: "Tytuł",
      link: "Link",
    },
    livesessions: {
      main: 'Sesja live'
    },
    assessmentmodule: {
      form: "Dodaj Assessment do modułu",
    },
    sections: {
      list: "Sekcje",
    },
    groups: {
      list: "Grupy",
      main: "Grupy",
      form: "Dodaj",
    },
    homeworks: {
      list: "Prace domowe",
    },
    schedules: {
      list: "Daty odblokowań modułów",
    },
    materials: {
      list: "Lista materiałów",
    },
    assessmentsmodule: {
      list: "Lista przypisanych assessmentów",
    },
    widgets: {
      list: "Spotkania online",
    },
    kickoffs: {
      list: "Kickoffs",
    },
    modules: {
      list: "Moduły",
    },
    preworks: {
      list: "Preworki",
    },
    assessments: {
      list: "Assessmenty",
    },
    reflections: {
      form: "Refleksja",
    },
    login: {
      main: "Logowanie",
    },
    users: {
      main: "Użytkownicy",
      list: "Lista użytkowników",
      form: "Dodaj użytkowników",
      reports: "Raporty",
      generator: "Generator haseł",
      edit: "Edytuj użytkownika",
    },
    announcements: {
      main: "Zapowiedzi",
      list: "Lista zapowiedzi",
      form: "Dodaj zapowiedź",
      edit: "Edytuj zapowiedź",
    },
    companies: {
      main: "Firmy",
      list: "Lista firm",
      form: "Dodaj firmę",
    },
    employees: {
      list: "Pracownicy",
      form: "Dodaj pracowników do tej firmy",
    },
    paths: {
      main: "Ścieżki",
      list: "Lista ścieżek",
      add: "Dodaj ścieżkę",
      edit: "Edytuj ścieżkę",
    },
    notifications: {
      main: "Push",
      form: "Powiadomienia push",
    },
    exams: {
      main: "Egzaminy",
      list: "Lista egzaminów",
      form: "Dodaj egzamin",
      results: "Wyniki egzaminów",
    },
    quizzes: {
      main: "Quizy",
      list: "Lista quizów",
      form: "Dodaj quiz",
    },
    vouchers: {
      main: "Vouchery",
      list: "Lista voucherów",
      form: "Dodaj voucher",
    },
    videos: {
      main: "Filmy",
      list: "Lista filmów",
      form: "Dodaj video",
      edit: "Edytuj video",
    },
    series: {
      main: "Serie",
      list: "Lista serii",
      form: "Dodaj serię",
      edit: "Edytuj serię",
    },
    categories: {
      main: "Kategorie",
      list: "Lista kategorii",
      form: "Dodaj kategorię",
      order: "Ustal kolejność",
    },
    authors: {
      main: "Autorzy",
      list: "Lista autorów",
      form: "Dodaj autora",
      edit: "Edytuj autora",
    },
    settings: {
      main: "Ustawienia",
      hero: "Hero",
      searcher: "Wyszukiwarka",
      slider: "Slider",
    },
    accredible: {
      main: "Accredible",
      list: "Lista accredible",
      form: "Dodaj accredible",
    },
    cohort: {
      main: "Kohorta",
      list: "Lista modułów",
      addModule: "Dodaj nowy moduł",
      addMaterial: "Dodaj nowy materiał",
      editModule: "Edytuj moduł",
      editMaterial: "Edytuj materiał",
    },
    wall: {
      main: "Wall",
      list: "Ustawienia walla",
      addModule: "Dodaj nowy moduł",
      editModule: "Edytuj moduł",
      form: "Dodaj moduł",
    },
    materialZone: {
      main: "Strefa materiałów",
      elements: "Lista materiałów",
      addElement: "Dodaj materiał",
      editElement: "Edytuj materiał",
      slides: "Lista slajdów",
      addSlide: "Dodaj slajd",
      editSlide: "Edytuj slajd",
    },
    programs: {
      main: "Programy",
      list: "Lista programów",
      addProgram: "Dodaj program",
      form: "Dodawanie programu",
      addSection: "Dodaj sekcję",
      addModule: "Dodaj moduł",
      addUser: "Dodaj uczestnika",
      addHomework: "Dodaj prace domową",
      addprework: "Dodaj prework",
      addGroup: "Dodaj grupę",
      addassessment: "Dodaj assessment",
      addLiveWebinar: "Dodaj spotkanie online",
      addPrecertificate: "Dodaj",
      addSchedule: "Dodaj date odblokowania modułu",
      addReflection: "Dodaj refleksję",
      addTemplate: "Dodaj szablon tablicy",
      addKickoff: "Dodaj kickoff",
      section: {
        form: "Sekcja",
      },
      module: {
        form: "Moduł",
      },
      groups: {
        form: "Grupa",
      },
      assessments: {
        categories: "Kategorie",
        questions: "Pytania",
      },
    },
    precertificates: {
      main: "Precertyfikacja",
      list: "Precertyfikacja",
      form: "Dodaj",
    },
  },
  options: {
    accredible: {
      placeholder: "Accredible",
      search: "Wyszukaj accredible",
    },
    authors: {
      placeholder: "Autor",
      search: "Wyszukaj autora",
    },
    company: {
      placeholder: "Firma",
      search: "Wyszukaj firmę",
    },
    series: {
      placeholder: "Seria",
      search: "Wyszukaj serię",
    },
    materialZone: {
      placeholder: "Element",
      search: "Wyszukaj element",
    },
  },
  languages: {
    what: {
      pl: "polski",
      en: "angielski",
      ae: "arabski",
    },
    materialZone: {
      placeholder: "Element",
      search: "Wyszukaj element",
    },
  },
  auth: {
    logout: "Wyloguj się",
  },
  optional: "opcjonalnie",
  choose: "Wybierz",
  acceptDelete: "Na pewno chcesz usunąć?",
  publication: "Publikacja",
  areYouSure: "Na pewno chcesz",
  cancelPublication: "anulować publikację",
  publish: "opublikować",
  confirmDraft: "Zatwierdź wersję roboczą",
  toConfirmDraft: "zatwierdzić wersję roboczą",
  show: "Pokaż",
  dp_programs: {
    main: "Programy rozwojowe",
    list: "Lista programów",
  },
};
